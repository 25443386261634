import { useEffect, useState, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { columnsAdventures } from '../../../services/columnsList';
import Button from '../../button'; 
import ModalC from '../../modal';
import FormConfig from './formConfig'; 
import Swal from "sweetalert2";
import { getDataAdventures, addDataAdventures, editDataAdventures, deleteDataAdventures } from '../../../services/ConfigAdventures';
import { addDataPaymentsAdventures, deleteTemporaryAdventureData, getTemporaryAdventureData, storeTemporaryAdventureData } from '../../../services/paymentAdventure';
import WWRConfirmationPDF from '../../../services/exports/emailNotification/WWRConfirmationPDF';
import { getDataInvoiceAdventure } from '../../../services/invoiceAdventure';
import { getData } from "../../../services/crm/clients";
import FormPayment from '../../invoiceAdventure/formConfig';
import adventureInvoicePDF from '../../../services/exports/adventureInvoicePDF';
import { UserAuth } from '../../../context/AuthContext';
import Loading from '../../../components/loading';
import { Input } from 'antd';
import '../../../style/table.css';
import { createCheckoutSession } from '../../payments/paymentForm';
import { fileHandler } from '../../../services/ConfigAdventures';
import Form from 'react-bootstrap/Form';
import { getDataOneUser } from '../../../services/auth/users';
import { roleVisit, roleSuperAdmin } from '../../../services/Tools';
import { sendEmail } from '../../../services/email/sendEmail';

function TableConfig({ clients }) {

  const [pending, setPending] = useState(true);
  const [data, setData] = useState({ rows: [] });
  const [modal, setModal] = useState(false);
  const [selects, setSelects] = useState([]);
  const [edit, setEdit] = useState(null);
  const [duplicate, setDuplicate] = useState(null);
  const [titleModal, setTitleModal] = useState('');
  const [expand, setExpand] = useState(true);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [dataInvoiceList, setDataInvoiceList] = useState({ rows: [] });
  const [allBookings, setAllBookings ] = useState(true);
  const { user, logOut } = UserAuth();

  const [modalPayment, setModalPayment] = useState(false); 
  const [paymentEdit, setPaymentEdit] = useState(null);
  const [titleModalPayment, setTitleModalPayment] = useState('');
  const [userInfo, setUserInfo ] = useState('');


  const [clientListAux, setClientListAux] = useState({ rows: [] });
  const [clientList, setClientList] = useState([{ value: '', label: '', email: '', phone: '', organization: '' }]);

  useEffect(() => {
    const fetchData = async () => {
      if (user != null) {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
      
        if (token) {
          let temp = await getTemporaryAdventureData(token);
  
          if (!temp) {
            showAll();
            return;
          }
  
          await editDataAdventures(temp?.aux, false);
          await addDataPaymentsAdventures(temp?.newData, true);
  
          setTimeout(() => {
            showAll();
            deleteTemporaryAdventureData(token);
            const url = new URL(window.location);
            url.searchParams.delete('token');
            window.history.pushState({}, '', url);
          }, 500);  
  
        } else {
          showAll();
          return;
        }
      }
    };
  
    fetchData();
    
    if (user != null) {
      const getUserData = async () => {
        const userDocSnap = await getDataOneUser(user.uid);
        if(userDocSnap?.length > 0){
          // if(userDocSnap[0?.role !== roleVisit]){
            setUserInfo(userDocSnap[0]);
          // }
          // else{
          //   localStorage.setItem('errorMessage', 'We are sorry but you do not have access to these system functions.');
          //   window.location.href = '/';
          // }
        }
      };
  
      getUserData();
    }
  
  }, [user]);
  

  // useEffect(async () => {
  //   if (user != null) {
  //     const userDocSnap = await getDataOneUser(user.uid);
  //     setUserInfo(userDocSnap);
  //   }
  // }, [user]);

  //Use by client List
  useEffect(() => {
    if (clientListAux?.rows?.length > 0) {
        var aux = [];
        clientListAux?.rows?.forEach(element => {
           let orga = element.organisation;
            if(orga){
                aux.push({ value: element.id, label: element.client, email: element.email, phone: element.phone, organization: orga });
            }else{
                aux.push({ value: element.id, label: element.client, email: element.email, phone: element.phone, organization : "-" });
            }
        });
        setClientList(aux);
    }
  }, [clientListAux]);

  const handleChange = ({ selectedRows }) => {
    setSelects(selectedRows);
  };

  const showAll = () => {
    setPending(true);
    if(!allBookings){
      getDataAdventures(setData, setPending, allBookings);
      setAllBookings(true);
    }else{
      getDataAdventures(setData, setPending, allBookings);
      setAllBookings(false);
    }
    setFilterText('');
    setDuplicate(null);
    setSelects([]);
    
    // getDataInvoiceAdventure(setDataInvoiceList);

    if(clients?.rows?.length > 0){
      setClientListAux(clients);
    }else{
      getData(setClientListAux);
    }
  }

  const action = (data, lastData) => {
    handleClearRows();

    if (edit) {
      editDataAdventures(data, true, lastData);
      setEdit(null);
    } else if (duplicate) {
      addDataAdventures(data, false, true);
      setDuplicate(null);
    } else{

      // if ( userInfo?.role == roleSuperAdmin){
        // if(data?.adventure?.label == "Whitewater Rafting" || data?.adventure?.internalId == "rafting1") {
        //   sendVerificationEmail(data, "Whitewater Rafting");
        // }
      // }
      
      addDataAdventures(data, true);
    }

    setModal(false);
    setTimeout(() => {
      getDataAdventures(setData, setPending, !allBookings);
      // getDataInvoiceAdventure(setDataInvoiceList);
      // getData(setClientListAux);
    }, 500);
  }

  // const sendVerificationEmail = async (data, emailType = null) => {
  //     const recipient = getClientEmail(data);
  //     const subject = "Booking confirmation";
  //     const text = generateEmailText(data);

  //     try {
  //       const pdfBytes = await WWRConfirmationPDF(data);
  //       sendEmail(recipient, subject, text, pdfBytes);
  //       // Aquí se puede mostrar un mensaje de confirmación al usuario

  //     } catch (error) {
  //       console.error('Failed to send email:', error);
  //       // Aquí se puede mostrar un mensaje de error al usuario

  //     }
  // }

//   const getClientEmail = (data) => {
//     const client = getClient(data);
//     return client ? client.email : '';
//   }

//   const getClient = (data) => {
//       return clientList.find(element => data?.client?.value === element?.value);
//   }

//   const generateEmailText = (data) => {
//     const clientName = data?.clientName || (data?.client?.label || "client");
//     return `
// Dear ${clientName},
      
// Thank you for booking with Tunnel49. 
      
// We have attached your booking confirmation to this email. Please read over it carefully and let us know if you would like to make any adjustments to your reservation by replying to this email. 
// Or, please feel free to call us at (250) 423-5008 if you prefer.


// Tunnel49
// Hannah Redfearn | Office Administrator 
// info@t49.ca  www.t49.ca   (250) 423-5008

// `;

// }

  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
    setSelects([]);
  }

  const editObj = (id) => {
    let aux = null;
    filteredItems.forEach(element => {
      if (element.id === id) {
        aux = element;
      }
    });
    setModal(true);
    setEdit(aux);
    setTitleModal('Edit Adventure');
  }

  const deleteObj = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        let aux = null;
        filteredItems.forEach(element => {
          if (element.id === id) {
            aux = element;
          }
        });
        deleteDataAdventures(aux);
        Swal.fire({
          title: 'Deleted!',
          text: 'Has been removed successfully.',
          icon: 'success',
          confirmButtonColor: '#173d38',
        })
        setTimeout(() => {
          setModal(false);
          getDataAdventures(setData, setPending, !allBookings);
          handleClearRows();
        }, 1000)
      }
    })
  }

  const generateInvoice = async (id) => {
    let aux = null;
    let auxClient = null;


    data?.rows.forEach(element => {

      if (element.id === id) {
        aux = element;
      }
    });

    if(!aux?.newClient){
      clientListAux?.rows?.forEach(element => {
        if (element.id === aux?.client?.value) {
          auxClient = element;
        }
      });
    }else{
      auxClient = aux?.newClientData;
    }

    adventureInvoicePDF(aux, auxClient);

  }

  const paymentObj = (id) => { 
    let aux = null;

    filteredItems.forEach(element => {
      if (element.id === id) {
        aux = element;
      }
    });

    // dataInvoiceList?.rows.forEach(element => {
    //   if (element?.idAdventure === id) {
    //     aux = element;
    //   }
    // });

    setModalPayment(true);
    setPaymentEdit(aux);
    setTitleModalPayment('Adventure Payment');
  }

  const actionPay = async (newData, copy_link = false) => {
    handleClearRows();
    let aux = null;

    filteredItems.forEach(element => {
      if (element.id === newData?.idAdventure) {
        aux = element;
      }
    });

    aux['paid'] = newData?.paid;
    aux['balance'] = newData?.balance;
    aux['amountPaid'] = newData?.amountPaid;
    aux['tipAmount'] = newData?.tipAmount;
    aux['tipAmountPaid'] = newData?.tipAmountTotalPaid;
    aux['invoicedVia'] = { value: newData?.paymentType?.value, label: newData?.paymentType?.label };
    newData['client'] = aux?.client;

    if(newData?.paymentType?.label === "Stripe"){
      if (paymentEdit) {
        let temp = {
          aux: aux,
          newData: newData
        }

        let id = await storeTemporaryAdventureData(temp);
        createCheckoutSession(newData?.newAmountPaid, newData?.adventure?.label, id, copy_link);
      }
    }else{
      editDataAdventures(aux, false);
      addDataPaymentsAdventures(newData, true);
      // getDataInvoiceAdventure(setDataInvoiceList);
      setModalPayment(false);
    }
  }

  const filteredItems = data.rows.filter(
    item => item?.adventure?.label && item?.adventure?.label.toLowerCase().includes(filterText.toLowerCase()) ||
    item?.client?.label && item?.client?.label?.toLowerCase().includes(filterText.toLowerCase()) ||
    item?.newClientData && item?.newClientData?.toLowerCase().includes(filterText.toLowerCase()) ||
    item?.id && item?.id?.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <>
        <h5 className='search-label'>Search</h5>
        <Input onChange={e => setFilterText(e.target.value)} filtertext={filterText} />
      </>
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
    { pending ? (
      <>
      <div style={{textAlign:"center"}}>
        <Loading></Loading>
      </div>
      </>
      ) 
      : (
      <>
      <div>
        <h3 style={{textAlign:"center"}}> { allBookings ? ("All adventures") : ("Today's adventures")}</h3> 
      </div>
      
      <div className="content">
        <div className="d-flex justify-content-between flex-row-reverse ">
          <div className="gap-2 m-3">

            <Button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={() => {
              setExpand(!expand);
            }} >{expand ? "Expand" : "Compress"}</Button>

            <Button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={() => { showAll()
              }} > { allBookings ? ( "Show today") : ("Show all") }</Button>

            { userInfo?.role !== roleVisit ? (
              <>
              { selects?.length > 0 ? (
                <Button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={() => {
                  if (selects.length === 1) {
                    setModal(true);
                    setDuplicate(selects[0]);
                    setTitleModal('Duplicate booking');
                    handleClearRows();
                  } else {
                    Swal.fire({
                      title: 'Warning!',
                      text: 'You must select a booking to duplicate, (ONLY ONE)',
                      icon: 'warning',
                      confirmButtonColor: "#173d38",
                  })
                  }
                }}> Duplicate Selected</Button>
              ) : ("") }
              
              <Button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={() => {
                setModal(true);
                setTitleModal('Add new adventure');
                setEdit(null);
              }} >+Add adventure</Button>
              </>
            ) : ("")}
            
          </div>
          {/* <div>
          <Form.Group controlId="formFile" className="mt-5 p-2">
            <Form.Control type="file" onChange={
              (e) => {
                fileHandler(e, setData);
              }
            } />
          </Form.Group>
        </div> */}
        </div>
        <div className="m-3">
          <DataTable
            columns={columnsAdventures(expand, editObj, deleteObj, generateInvoice, paymentObj, userInfo)}
            data={filteredItems}
            selectableRows
            onSelectedRowsChange={handleChange}
            pagination
            clearSelectedRows={toggledClearRows}
            defaultSortFieldId={9}
            defaultSortAsc={false}
            progressPending={pending}
            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
          />
          <ModalC
            show={modal}
            onHide={() => { setModal(false); setEdit(null); setDuplicate(null);}}
            changeSize={true}> 
            <FormConfig title={titleModal} lastData={edit ? edit : duplicate} textButton={titleModal} setDuplicate={setDuplicate} action={action} setModal={setModal} clients={clientListAux}></FormConfig>
          </ModalC>

          <ModalC
            show={modalPayment}
            onHide={() => setModalPayment(false)}>
            <FormPayment title={titleModalPayment} data={paymentEdit} textButton={titleModalPayment} actionPay={actionPay} setModalPayment={setModalPayment}></FormPayment>
          </ModalC>

        </div>
      </div>
    </>
    )}
    </>

  );
}

export default TableConfig;
