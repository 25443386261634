import { query, collection, getDocs, doc, setDoc, deleteDoc, updateDoc } from "firebase/firestore";
import { auth, db } from '../firebase'
import Swal from "sweetalert2";
import { v4 as uuid } from "uuid";
import { numtoDate, monthNames, dayOfWeekNames, formatDate, twoDigitPad } from './Tools';
import { server } from './serverName/Bookings';
import { ExcelRenderer } from 'react-excel-renderer';
import transConfirmationPDF from './exports/emailNotification/transConfirmationPDF';
import YXCConfirmationPDF from './exports/emailNotification/YXCConfirmationPDF';
import WWRConfirmationPDF from './exports/emailNotification/WWRConfirmationPDF';
import { sendEmail } from './email/sendEmail';

export const getData = async (setdata, setPending = null) => {
    try {
      if (auth.currentUser != null) {
        const q = query(collection(db, server));
        const querySnapshot = await getDocs(q);
  
        setdata({ rows: [] });
  
        var array = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          array.push(data);
        });
        setdata({
          rows: array
        });
  
        if (setPending) {
          setPending(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
}

export const sendVerificationEmail = async (data, clientList, setSendEmailPending = null, emailType = null) => {

  const recipient = getClientEmail(data, clientList);
  const subject = "Booking confirmation";
  const text = generateEmailText(data);

  try {
    if(emailType === "YYC"){
      const pdfBytes = await transConfirmationPDF(data);
      sendEmail(recipient, subject, text, pdfBytes);
    }else if(emailType === "YXC"){
      const pdfBytes = await YXCConfirmationPDF(data);
      sendEmail(recipient, subject, text, pdfBytes);
    }
    
    // Aquí se puede mostrar un mensaje de confirmación al usuario

  } catch (error) {
    console.error('Failed to send email, transConfirmationPDF, sendEmail');
    // Aquí se puede mostrar un mensaje de error al usuario
  }
  if (setSendEmailPending) {
    setSendEmailPending(false);
  }
}

export const sendVerificationEmailWWR = async (data, clientList, setSendEmailPending = null, emailType = null) => {
  const recipient = getClientEmail(data, clientList);
  const subject = "Booking confirmation";
  const text = generateEmailText(data);

  try {
    const pdfBytes = await WWRConfirmationPDF(data);
    sendEmail(recipient, subject, text, pdfBytes);
    // Aquí se puede mostrar un mensaje de confirmación al usuario

  } catch (error) {
    console.error('Failed to send email:', error);
    // Aquí se puede mostrar un mensaje de error al usuario
  }
  if (setSendEmailPending) {
    setSendEmailPending(false);
  }
}

const getClientEmail = (data, clientList) => {
  const client = getClient(data, clientList);
  return client ? client.email : '';
}

const getClient = (data, clientList) => {
  return clientList.find(element => data?.client?.value === element?.value);
}

const generateEmailText = (data) => {
  const clientName = data?.clientName || (data?.client?.label || "client");
  return `
Dear ${clientName},
    
Thank you for booking with Tunnel49. 
    
We have attached your booking confirmation to this email. Please read over it carefully and let us know if you would like to make any adjustments to your reservation by replying to this email. 
Or, please feel free to call us at (250) 423-5008 if you prefer.


Tunnel49
Noah Kramer | Sales and Marketing 
info@t49.ca  www.t49.ca   (250) 423-5008

`;

}


// export const fileHandler = (event, setData) => {
//   let fileObj = event.target.files[0];
//   //just pass the fileObj as parameter
//   ExcelRenderer(fileObj, async (err, resp) => {
//     var array = [];
//     if (err) {
//       console.log(err);
//     }
//     else {
//       resp.rows.forEach(async (element, i) => {
//         if (i !== 0 && element[0]) {
//           var newObject = {
//             client: element[0] ? element[0] : '',
//             organisation: element[1] ? element[1] : '',
//             email: element[2] ? element[2] : '',
//             source: element[3] ? element[3] : '',
//             phone: element[4] ? element[4] : '',
//             status: element[5] ? element[5] : '',
//             owner: element[6] ? element[6] : '',
//             date: element[7] ? formatDate(numtoDate(element[7]), 'yyyy-MM-dd') : '',
//             modified: element[8] ? formatDate(numtoDate(element[8]), 'yyyy-MM-dd') : '',
//             nop: element[9] ? element[9] : '',
//             requested: element[10] ? formatDate(numtoDate(element[10]), 'yyyy-MM-dd') : '',
//             return_travel: element[11] ? formatDate(numtoDate(element[11]), 'yyyy-MM-dd') : '',
//             request_type: element[12] ? element[12] : '',
//             vehicle: element[13] ? element[13] : '',
//             value: element[14] ? element[14] : '',
//             booked: element[15] ? element[15] : '',
//             feedback: element[16] ? element[16] : '',
//           }
//           await addData(newObject);
//           array.push(newObject);
//         }
//       });
//       setData({ rows: array });
//     }

//     Swal.fire({
//       icon: "success",
//       title: "Imported",
//       text: "The clients was created successfully",
//       confirmButtonColor: '#173d38',
//       confirmButtonText: "Ok",
//     });
//   });
// }