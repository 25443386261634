import React, {useCallback, useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import '../../style/calendar.css';
import { getDataReservations, editDataReservations, getClientReservations } from '../../services/ConfigReservations';
import { getDataAdventures, editDataAdventures } from '../../services/ConfigAdventures';
import { getDataRentals, editDataRentals } from '../../services/ConfigRentals';
import "react-big-calendar/lib/css/react-big-calendar.css";
import { UserAuth } from "../../context/AuthContext";
import ModalC from "../modal";
import FormConfigA from "../bookingTable/adventures/formConfig";
import FormConfigR from "../bookingTable/rentals/formConfig";
import FormConfigReser from "../bookingTable/reservations/formConfig";
import { useParams } from 'react-router-dom';
import Loading from '../../components/loading';
import Button from '../../components/button'; 

const now = new Date();

const localizer = momentLocalizer(moment);

function MyCalendar(){

  const [myEvents, setEvents] = useState([]);
  const [dataA, setDataA] = useState({ rows: [] });
  const [dataR, setDataR] = useState({ rows: [] });
  const [dataReser, setDataReser] = useState({ rows: [] });
  const [pending, setPending] = useState(true);
  const [pendingReser, setPendingReser] = useState(true);
  const [modalA, setModalA] = useState(false);
  const [modalR, setModalR] = useState(false);
  const [modalReser, setModalReser] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [edit, setEdit] = useState(null);
  const { user } = UserAuth();
  const [ reservationData, setReservationData ] = useState({rows: []});
  let { tap } = useParams();

  useEffect(() => {
    if (user != null) {
      getDataReservations(setDataReser, setPending);
      getDataAdventures(setDataA, setPending);
      getDataRentals(setDataR, setPending);

      setTimeout(() => {
        if (tap?.length > 1) {
          getClientReservations(tap, setReservationData, setPendingReser);
        }
      }, 300);
    }
  }, [user]);


  useEffect(() => {
    if (dataReser?.rows?.length !== 0) {
      setEvents([]);
      if(tap?.length > 1){
        // prepareActivites(reservationData.rows);
        // prepareActivites(dataA.rows.concat(dataR.rows).concat(dataReser.rows));
        prepareActivites(dataA.rows.concat(dataReser.rows));

      }else{
        // prepareActivites(dataA.rows.concat(dataR.rows).concat(dataReser.rows));
        prepareActivites(dataA.rows.concat(dataReser.rows));
        // prepareActivites(dataReser.rows);
      }
    }
  }, [reservationData,dataReser]);

  useEffect(() => {
    if (reservationData) {
      // maybe trigger a loading screen
      let event = [];

      // if(reservationData?.rows?.[0]){
      //   event = {
      //     id: reservationData?.rows?.[0]?.id,
      //     title: reservationData?.rows?.[0]?.shuttle?.label + " " +reservationData?.rows?.[0]?.startTime + "-" + reservationData?.rows?.[0]?.endTime,
      //     start: new Date(reservationData?.rows?.[0]?.reservationData?.rows?.[0]+"T"+reservationData?.rows?.[0]?.startTime),
      //     end: new Date(reservationData?.rows?.[0]?.endDate+"T"+reservationData?.rows?.[0]?.endTime),
      //     status: 'Reservations',
      //     reservation: reservationData?.rows?.[0]
      //   }
      // }
      
      handleSelectEvent(event);

      return;
    }
  }, [reservationData]);

  const prepareActivites = (data) => {
    data.forEach(element => {
      let event = [];

      if(element?.refund && element?.amountPaid === 0 && element?.balance === 0){

      }else{
        if(element?.shuttle){
          event = {
            id: element.id,
            title: element?.pickUp?.label + " - " + element?.dropOff?.label,
            start: new Date(element?.startDate+"T"+element?.startTime),
            end: new Date(element?.endDate+"T"+element?.endTime),
            status: 'Reservations',
            startDate: element?.startDate,
            service: element?.shuttle?.label,
            endDate: element?.endDate,
            reservation: element
          }
        }
        else if(element?.adventure){
          event = {
            id: element.id,
            title: element?.adventure?.label + " " +element?.startTime + "-" + element?.endTime,
            start: new Date(element?.startDate+"T"+element?.startTime),
            end: new Date(element?.endDate+"T"+element?.endTime),
            status: 'Adventure',
            adventure: element
          }
        } 
        // else if(element?.rental){
        //   event = {
        //     id: element.id,
        //     title: element?.rental?.label + " " +element?.pickupTime + "-" + element?.dropOffTime,
        //     start: new Date(element?.pickupDate+"T"+element?.pickupTime),
        //     end: new Date(element?.dropOffDate+"T"+element?.dropOffTime),
        //     status: 'Rentals',
        //     rental: element
        //   }
        // }
      }
      
      setEvents((prev) => [...prev, event])
    });
  }

  const action = (data, invoice) => {
    if (edit) {
      if(data?.shuttle){
        // editDataReservations(data,true);
        editDataReservations(data, true);
        setModalReser(false);
      } else if(data?.adventure){
        editDataAdventures(data, true);
        setModalA(false);
      } 
      // else if(data?.rental){
      //   editDataRentals(data, true);
      //   setModalR(false);
      // }
    } 
    setTimeout(() => {
      getDataReservations(setDataReser, setPending);
      getDataAdventures(setDataA);
      getDataRentals(setDataR);
    }, 500);
  }

  // No delete, is import for future
  const handleSelectSlot = useCallback(
    ({ start, end }) => {
      const title = window.prompt('New Event Name')
      if (title) {
        setEvents((prev) => [...prev, { start, end, title }])
      }
    },
    [setEvents]
  )

  const handleSelectEvent = useCallback(
    (event) => { 
      if(event?.status === 'Reservations'){
        setModalReser(true);
        setEdit(event?.reservation);
        setTitleModal('Edit reservation');
      } else if(event?.status === 'Adventure'){
        setModalA(true);
        setEdit(event?.adventure);
        setTitleModal('Edit Adventure');
      } 
      // else if(event?.status === 'Rentals'){
      //   setModalR(true); 
      //   setEdit(event?.rental);
      //   setTitleModal('Edit rental');
      // }
    },
    []
  )

  const eventPropGetter = useCallback(
   
    (event) => {

      let backgroundColor;

      if (event.status.includes('Reservations')) {

        backgroundColor = event?.reservation?.shuttle?.color ? event?.reservation?.shuttle?.color : "#EEEEEE"; // ? "#173d38" : "green";

      } else if (event.status.includes('Adventure')) {

        backgroundColor = event?.adventure?.adventure?.value ? event?.adventure?.adventure?.value : "#EEEEEE";// ? "#173d38" : "green";

      } 
      // else if (event.status.includes('Rentals')) {

      //   backgroundColor = '#399c07';

      // }
  
      return { style: { backgroundColor } };

    },
    []
  );

  // const tooltipAccessor = (event) => {
  //   return (
  //     <div>
  //       <strong>{event.title}</strong>
  //       <p>{event.endDate}</p>
  //     </div>
  //   );
  // };

  const showAll = () => {
    if(reservationData?.rows?.length > 0){

      //window.history.replaceState("","", "/booking")
      setReservationData({rows: []});

      setDataA(prepareActivites(dataReser.rows));

    }else{
      //setReservationData({rows: []});
    }
  }

  return (
    <>
    <div className="App calendar">
      {/* { tap?.length > 1 ? (
          !pendingReser ? (
            reservationData?.rows?.length > 1? (
              
              <>              
              <div className="d-flex justify-content-between flex-row-reverse mb-2"> */}

                {/* <div className="gap-2 m-1">
                  <Button className="color-green btn btn-outline-secondary" onClick={() => { showAll()}} > Show all transports </Button>
                </div> */}
                {/* <div style={{margin:"auto"}}>
                  <h3 style={{textAlign:"center"}}> { reservationData?.rows?.[0]?.client?.label} transports</h3> 
                </div>
              </div>

              <Calendar
              defaultDate={ moment(reservationData?.rows?.[0]?.startDate, 'YYYY-MM-DD') }
              defaultView={"month"}
              localizer={localizer}
              resizable
              style={{ height: "100vh" }}
              eventPropGetter={eventPropGetter}
              events={myEvents}
              onSelectEvent={handleSelectEvent}
              //onSelectSlot={handleSelectSlot}
              // tooltipAccessor={tooltipAccessor}
              selectable
            />
            </>
            ) : (
              <>
              <Calendar
                defaultDate={ moment().toDate() }
                defaultView={"month"}
                localizer={localizer}
                resizable
                style={{ height: "100vh" }}
                eventPropGetter={eventPropGetter}
                events={myEvents}
                onSelectEvent={handleSelectEvent}
                //onSelectSlot={handleSelectSlot}
                // tooltipAccessor={tooltipAccessor}
                selectable
              />
              </>
            )

          ) : ( 
            <div className='d-flex justify-content-center'>
              <Loading></Loading>
            </div>
          )
      ) : ( */}



        {  pending ? (
              <div className='d-flex justify-content-center'>
                <Loading></Loading>
              </div>
          ) : (
            <>

            <Calendar
                defaultDate={ moment().toDate() }
                defaultView={"month"}
                localizer={localizer}
                resizable
                style={{ height: "100vh" }}
                eventPropGetter={eventPropGetter}
                events={myEvents}
                onSelectEvent={handleSelectEvent}
                //onSelectSlot={handleSelectSlot}
                // tooltipAccessor={tooltipAccessor}
                selectable
              />
            </>
          )
        }


          
      {/* ) } */}

      <ModalC
        show={modalReser}
        onHide={() => setModalReser(false)}
        changeSize={true}>
        <FormConfigReser title={titleModal} lastData={edit} textButton={titleModal} action={action} setModal={setModalReser}></FormConfigReser>
      </ModalC>

      <ModalC
        show={modalA}
        onHide={() => setModalA(false)}        
        changeSize={true}>
        <FormConfigA title={titleModal} lastData={edit} textButton={titleModal} action={action} setModal={setModalA}></FormConfigA>
      </ModalC>

      {/* <ModalC
        show={modalR}
        onHide={() => setModalR(false)}
        changeSize={true}>
        <FormConfigR title={titleModal} lastData={edit} textButton={titleModal} action={action} setModal={setModalR}></FormConfigR>
      </ModalC>  */}

    </div>
    </>
  );
  
}

export default MyCalendar;